import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-error-dialog',
  templateUrl: './generic-error-dialog.component.html',
  styleUrls: ['./generic-error-dialog.component.scss']
})
export class GenericErrorDialogComponent implements OnInit {


  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<GenericErrorDialogComponent>) {

    
   }

  ngOnInit(): void {
  }

  dismiss() {
    this.dialogRef.close();
  }

}
