import {Component, Inject, Optional} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'yesnodialog',
    templateUrl: 'yesnodialog.component.html',
    styleUrls:['yesnodialog.component.scss']
  })
  export class YesNoDialogComponent {


    constructor( @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<YesNoDialogComponent>) {


    }

    yesButton() {
      this.dialogRef.close(true);

    }

    noButton() {
      this.dialogRef.close(false);
    }
  }