import {Component, Inject, Optional} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'okdialog',
    templateUrl: 'okdialog.component.html',
    styleUrls:['okdialog.component.scss']
  })
  export class OkDialogComponent {


    constructor( @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {


    }
  }