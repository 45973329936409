import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private _loading: boolean = false;
  loadingStatus: Subject<boolean> = new Subject<boolean>();

  show() {
    this._loading = true;
    this.loadingStatus.next(true);
  }

  hide() {
    this._loading = false;
    this.loadingStatus.next(false);
  }

  get loading(): boolean {
    return this._loading;
  }
}
