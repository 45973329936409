<h2 mat-dialog-title color="warn">Unexpected Error</h2>
<mat-dialog-content class="mat-typography">
  <h3>Error occurred. Please send screenshot to FMB Administrator</h3>

  <p> {{ data.error | json }} </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close (click)="dismiss()">Okay</button>
</mat-dialog-actions>

